<template>
  <div class="candidate">
    <div v-if="showData">
      <b-row class="px-3">
        <b-col md="12" lg="2" class="pl-0">
          <div class="border_block p-3">
            <!-- Candidate personal info -->
            <div class="candidate__details">
              <div class="candidate__details__title">
                {{ $t('match_references.candidate_info') }}
                <span v-if="item.status" :class="`text-nowrap status-${item.status}`">{{
                  $t(`applicant_portal.available_fields.${item.status}`)
                }}</span>
              </div>
              <div class="candidate__details__avatar" v-if="avatar">
                <img :src="avatar" width="80" />
              </div>
              <div class="candidate__details__avatar" v-if="!avatar">
                <img :src="logoAuthor" width="60" />
              </div>
              <div v-if="item.first_name" class="candidate__details__title">
                <span v-if="item.title">{{ item.title }}.</span>
                {{ item.first_name }}
                {{ item.last_name }}
              </div>
              <div v-if="item.date_of_birth">
                <b-icon icon="calendar-date-fill"> </b-icon>
                {{ item.date_of_birth }}
              </div>
              <div v-if="item.email" class="pb-3">
                <a :href="'mailto:' + item.email">
                  <img :src="emailPicture" width="30" />
                </a>
              </div>
              <div v-if="item.mobile" class="pb-3">
                <b-icon icon="telephone-fill"> </b-icon>
                {{ item.mobile }}
              </div>
              <div v-if="item.location">
                <b-icon icon="geo-alt-fill"> </b-icon>
                {{ item.location }}
              </div>
              <a v-if="item.linkedin" :href="checkAbsUrl" target="_blank">
                <b-icon icon="linkedin" style="color: black" font-scale="2">
                </b-icon>
              </a>
            </div>
            <!-- Candidate documents -->
            <div class="candidate__details">
              <div class="candidate__details__title">
                {{ $t('seeker_account.documents') }}
                <span v-if="isCompanyAdmin">
                  <b-btn
                    variant="primary"
                    size="sm"
                    @click="showDocumentAddModal = !showDocumentAddModal"
                    >{{ $t('dashboard.add') }}</b-btn
                  >
                  <ModalAddDocument
                    v-if="showDocumentAddModal"
                    @submit="(file) => uploadCandidateDocument(file)"
                  />
                </span>
              </div>
              <div v-if="documents.length">
                <div
                  class="candidate__details__item"
                  v-for="(document, key) in documents"
                  :key="key"
                >
                  <b-icon
                    icon="file-earmark-text"
                    style="color: black; margin-right: 5px"
                    font-scale="2"
                    @click="downloadDocument(document)"
                  >
                  </b-icon>
                  <strong>{{ document.document_type.title }}</strong>
                  <b-icon
                    v-if="isCompanyAdmin"
                    icon="trash"
                    style="margin-left: 3px"
                    font-scale="1.3"
                    @click="openDocumentDeleteModal(document.id)"
                  >
                  </b-icon>
                </div>
                <b-modal
                  centered
                  v-model="showDocumentDeleteModal"
                  hide-footer
                  hide-header
                >
                  <DocumentDeleteModal
                    :id="currentDocumentId"
                    @close="() => (showDocumentDeleteModal = false)"
                    @deletedocument="deleteCandidateDocument"
                  ></DocumentDeleteModal>
                </b-modal>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="12" lg="3">
          <div class="border_block p-3">
            <!-- Candidate educations -->
            <div class="candidate__details" v-if="item.educations">
              <div class="candidate__details__title">
                {{ $t('applicant_portal.educations') }}
              </div>
              <div v-for="(education, key) in item.educations" :key="key">
                <div v-if="education.discipline">
                  <div class="candidate__details__item">
                    <strong>{{ education.graduation_year }}</strong> -
                    <span
                      v-if="education.education && education.education.title"
                      >{{ education.education.title }}</span
                    >
                    in {{ education.discipline }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Candidate languages -->
            <div class="candidate__details" v-if="languages">
              <div class="candidate__details__title">
                {{ $t('general.languages') }}
              </div>
              <div
                class="candidate__details__item"
                :class="{ gray: language.unconfirmed }"
                v-for="(language, key) in languages"
                :key="key"
              >
                <strong>{{ getLanguageName(language.value) }}</strong> -
                {{ language.language_proficiency_level.title }}
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="12" lg="3">
          <div class="border_block p-3">
            <!-- Candidate experiences -->
            <div class="candidate__details" v-if="item.experiences">
              <div class="candidate__details__title">
                {{ $t('applicant_portal.experiences') }}
              </div>
              <div
                v-for="(experience, key) in item.experiences"
                :key="Date.now() + key"
              >
                <div v-if="experience.job_role">
                  <div class="candidate__details__item">
                    <div>
                      <b>{{ experience.job_role }}</b>
                    </div>
                    <div
                      v-if="
                        experience.job_specialization &&
                        experience.job_specialization.title
                      "
                    >
                      {{ experience.job_specialization.title }}
                    </div>
                    <div>
                      <b-badge
                        class="candidate__details__badge candidate__details__badge--yelow"
                      >
                        {{ experience.seniority_level.title }}
                      </b-badge>
                      <b-badge
                        class="candidate__details__badge candidate__details__badge--purple"
                      >
                        {{ experience.years_of_experience.title }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="border_block mt-3 p-3" v-if="questions.length">
            <!-- Questions -->
            <div class="candidate__details__title">
              {{ $t('seeker_account.your_questions') }}
            </div>
            <div class="candidate__details">
              <div
                class="candidate__details__item"
                v-for="(question, key) in questions"
                :key="question.value + key"
              >
                <!-- <b-form-checkbox
                  v-if="isCompanyAdmin"
                  class="mr-1 question-checkbox"
                  v-model="question.confirmed"
                  @change="updateConfirmQuestion(question)"
                >
                </b-form-checkbox> -->
                <img :src="question.confirmed ? yesIcon : noIcon" width="30" />
                <span :class="{ gray: !question.confirmed }" class="pl-2">
                  {{ question.text }}
                </span>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="12" lg="4">
          <div class="border_block p-3">
            <!-- Candidate certificates -->
            <div class="candidate__details__title">
              {{ $t('applicant_portal.certificates') }}
            </div>
            <div class="candidate__details" v-if="certificates.length">
              <div
                class="candidate__details__item"
                v-for="(certificate, key) in certificates"
                :key="certificate.title + key"
              >
                <li>{{ certificate.title }}</li>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else class="candidate__no__data">
      <div>No data</div>
    </div>
  </div>
</template>

<script>
import LogoAuthor from '../../../../images/logoAuthor.svg';
import EmailPicture from '../../../../images/emailPicture.svg';
import YesIcon from '../../../../images/icons/yes.svg';
import NoIcon from '../../../../images/icons/no.svg';
import ModalAddDocument from './UploadCandidateDocumentModal/Index';
import DocumentDeleteModal from './DocumentDeleteModal';
import { mapGetters } from 'vuex';
import { uniqBy } from 'lodash';

export default {
  components: {
    ModalAddDocument,
    DocumentDeleteModal,
  },
  props: {
    item: {
      type: Object,
      require: true,
      default: () => {},
    },
    availableLanguages: {
      type: Array,
      default: () => [],
    },
    anonymous: {
      type: Boolean,
      default: false,
    },
    isAnalytic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDocumentAddModal: false,
      showDocumentDeleteModal: false,
      currentDocumentId: '',
      documents: null,
    };
  },
  computed: {
    ...mapGetters('campaign', ['current']),
    ...mapGetters('global', ['isCompanyAdmin']),
    logoAuthor() {
      return LogoAuthor;
    },
    emailPicture() {
      return EmailPicture;
    },
    yesIcon() {
      return YesIcon;
    },
    noIcon() {
      return NoIcon;
    },
    filteredDocuments() {
      return !this.anonymous
        ? this.item.documents.filter(
            (d) => d.document_name !== 'xmz-avatar.jpg'
          )
        : [];
    },
    avatar() {
      const avatar = this.item.documents
        .reverse()
        .find((d) => d.document_name === 'xmz-avatar.jpg');
      if (!avatar || this.anonymous) return null;
      const avatarUrl = avatar.file.url;
      // handle dev enviroment
      if (!avatarUrl.includes('https') && !avatarUrl.includes('http')) {
        return `http://app.examiz.local${avatarUrl}`;
      }
      return avatarUrl;
    },
    showInfo() {
      return (
        !!this.item.date_of_birth ||
        !!this.item.mobile ||
        !!this.item.location ||
        !!this.item.linkedin
      );
    },
    showData() {
      return (
        this.showInfo ||
        this.item.educations.length ||
        this.item.experiences.length ||
        this.item.certificates.length ||
        this.item.languages.length ||
        this.filteredDocuments.length
      );
    },
    keywordsCandidate() {
      return this.item.certificates
        .concat(...this.item.educations.map((e) => e.education_keywords))
        .concat(...this.item.experiences.map((e) => e.job_keywords));
    },
    keywordsDemand() {
      return this.current.ap_data.certificates
        .concat(...this.current.ap_data.education_keywords)
        .concat(...this.current.ap_data.job_keywords);
    },
    languages() {
      const existingLanguages = this.item.languages;
      const transformedLanguages = this.current.ap_data.languages
        .filter(
          (apLang) =>
            !existingLanguages.some(
              (itemLang) => itemLang.title === apLang.text
            )
        )
        .map((apLang) => ({
          ...apLang,
          title: apLang.text,
          unconfirmed: true,
          language_proficiency_level: {
            id: apLang.language_proficiency_level_id,
            title: apLang.language_proficiency_level_title,
          },
        }));

      return existingLanguages.length
        ? [...existingLanguages, ...transformedLanguages]
        : [];
    },
    questions() {
      return this.keywordsDemand.map((question) => {
        const exists = this.keywordsCandidate.some(
          (exist) => exist.title === question.value
        );
        if (!exists) return { ...question, confirmed: false };
        return { ...question, confirmed: true };
      });
    },
    certificates() {
      const uniqueArray = uniqBy(this.keywordsCandidate, 'title').filter(
        (item) => !this.keywordsDemand.some((el) => el.value === item.title)
      );
      return this.isAnalytic ? uniqueArray.slice(0, 5) : uniqueArray;
    },
    checkAbsUrl() {
      return this.item.linkedin.startsWith('http') ||
        this.item.linkedin.startsWith('https')
        ? this.item.linkedin
        : 'https://' + this.item.linkedin;
    },
  },
  created() {
    this.documents = this.filteredDocuments;
  },
  methods: {
    getLanguageName(code) {
      return this.availableLanguages.find((obj) => obj.value === code).text;
    },
    getAvatar() {
      return this.items.find();
    },
    downloadDocument(document) {
      window.open(document.file.url, '_blank');
    },
    uploadCandidateDocument(data) {
      this.showDocumentAddModal = false;

      this.$store
        .dispatch('campaign/uploadCandidateDocuments', {
          candidateId: this.item.id,
          documents: [data],
        })
        .then(() => this.documents.push(data));
    },
    deleteCandidateDocument(documentId) {
      this.documents = this.documents.filter(
        (document) => document.id !== documentId
      );
      this.$store.dispatch('campaign/updateCandidateAP', {
        candidateId: this.item.id,
        documents: this.documents,
      });
      this.showDocumentDeleteModal = false;
      this.currentDocumentId = '';
    },
    openDocumentDeleteModal(id) {
      this.showDocumentDeleteModal = true;
      this.currentDocumentId = id;
    },
    updateConfirmQuestion(question) {
      this.$store.dispatch('campaign/updateCandidateAP', {
        candidateId: this.item.id,
        question,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.border_block {
  border: 2px solid gray;
  border-radius: 15px;
  min-height: 200px;
}
.gray {
  text-decoration: line-through;
  color: gray;
}

@media only screen and (max-width: 991px) {
  .border_block {
    border: none;
    min-height: 0;
  }
}

.candidate {
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    display: block;
  }

  &__details {
    margin: 5px;

    &__title {
      font-weight: bold;
      padding-bottom: 15px;
      font-size: 1.5rem;
      color: #000;
      margin-top: 5px;
    }

    &__avatar {
      padding-bottom: 15px;
      img {
        max-width: 130px;
      }
    }

    &__item {
      padding-bottom: 10px;
    }

    &__badge {
      padding: 0.4rem 0.5rem;
      margin-right: 5px;

      &--yelow {
        background: #fbf6a2;
        color: #000;
      }

      &--purple {
        background: #e6ceff;
        color: #000;
      }
    }
  }

  &__no__data {
    width: 100%;
    text-align: center;
    background-color: #f3f4f5;
    color: #989898;
    padding: 20px 0px 20px 0px;
  }
  .question-checkbox {
    display: inline-block !important;
  }
}
</style>
