<template>
  <ValidationObserver ref="form" tag="div">
    <div class="step__item__content">
      <div class="step__item__index">{{ $t('match_candidate.step_2') }}</div>
      <div class="step__item__title">{{ $t('applicant_portal.specify_job_skill') }}</div>
      <div class="step__item__description">
        {{ $t('applicant_portal.kind_of_job') }}
      </div>
      <b-button
        v-if="!showEditBtn"
        variant="primary"
        size="md"
        v-b-toggle.step-2
        class="step__item__trigger"
        :disabled="isDisabled"
        >{{ $t('general.setup') }}</b-button>

      <b-button
        v-if="showEditBtn"
        variant="outline-primary"
        size="md"
        v-b-toggle.step-2
        class="step__item__trigger"
        >{{ $t('dashboard.edit') }}</b-button>
      <b-collapse ref="step2" id="step-2" class="steptoggle__content mb-0">
        <b-row>
          <b-col lg="12">
          <!-- <b-col class="col-12"> -->
            <!-- assessment scope ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
            <!-- <div class="form__title">Assessment Scope</div> -->

            <b-form>
              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('applicant_portal.education') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/business_idea.svg"
                      alt="Business"
                      width="60"
                    />
                  </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <b-form-group :label="`${$t('applicant_portal.education_level')}`">
                    <ValidationProvider v-slot="{ errors }">
                      <TableMultiselect
                        v-model="form.education_levels"
                        :options="highestEducations"
                      />

                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group>

                  <!-- <b-form-group :label="`${$t('applicant_portal.education_areas')}`">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TableMultiselect
                        ref="educationAreasRef"
                        v-model="form.education_areas"
                        :options="educationAreas"
                        :isApiSearch="true"
                        @searchChange="onEducationAreasSearchChange"
                      />

                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group> -->

                  <b-form-group :label="`${$t('applicant_portal.education_groups')}`">
                    <ValidationProvider v-slot="{ errors }">
                      <TableMultiselect
                        ref="educationGroupsRef"
                        v-model="form.education_groups"
                        :options="educationGroups"
                        :isApiSearch="true"
                        :placeholder="$t('create_campaign.education_groups_placeholder')"
                        @searchChange="onEducationGroupsSearchChange"
                      />

                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group>

                  <!-- <b-form-group :label="`${$t('applicant_portal.education_specializations')}`">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TableMultiselect
                        ref="educationSpecializationsRef"
                        v-model="form.education_specializations"
                        :options="educationSpecializations"
                        :isApiSearch="true"
                        @searchChange="onEducationSpecializationsSearchChange"
                      />

                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group> -->

                  <b-form-group :label="`${$t('applicant_portal.focus_area')} (${$t('create_campaign.keywords')})`">
                    <TableMultiselect
                        v-model="form.education_keywords"
                        :taggable="true"
                        :readonly="isCampaignReadOnly"
                        :placeholder="$t('create_campaign.select_existing_or_add_new_placeholder')"
                      />
                  </b-form-group>
                </div>
              </div>
              <hr>

              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('general.languages') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/discussion.svg"
                      alt="Discussion"
                      width="60"
                    />
                  </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <b-form-group :label="`${$t('general.languages')}`">
                    <ValidationProvider v-slot="{ errors }">
                      <TableMultiselect
                        v-model="form.languages"
                        :preselectFirst="true"
                        :options="availableLanguages"
                        :actionsOptions="languageProficiencyLevels"
                      />
                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group>
                </div>
              </div>
              <hr>

              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('applicant_portal.qualification') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/business_settings.svg"
                      alt="Settings"
                      width="60"
                    />
                  </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <!-- <b-form-group :label="`${$t('applicant_portal.job_areas')}`">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TableMultiselect
                        ref="jobAreasRef"
                        v-model="form.job_areas"
                        :options="jobAreas"
                        :isApiSearch="true"
                        @searchChange="onJobAreasSearchChange"
                      />

                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group> -->

                  <b-form-group :label="`${$t('applicant_portal.job_groups')}`">
                    <ValidationProvider v-slot="{ errors }">
                      <TableMultiselect
                        ref="jobGroupsRef"
                        v-model="form.job_groups"
                        :options="jobGroups"
                        :isApiSearch="true"
                        :placeholder="$t('create_campaign.job_groups_placeholder')"
                        @searchChange="onJobGroupsSearchChange"
                      />

                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group>

                  <b-form-group :label="`${$t('applicant_portal.job_specializations')}`">
                    <ValidationProvider v-slot="{ errors }">
                      <TableMultiselect
                        ref="jobSpecializationsRef"
                        v-model="form.job_specializations"
                        :options="jobSpecializations"
                        :isApiSearch="true"
                        :placeholder="$t('create_campaign.job_specializations_placeholder')"
                        @searchChange="onJobSpecializationsSearchChange"
                      />

                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group>

                  <b-form-group :label="`${$t('applicant_portal.focus_area')} (${$t('create_campaign.keywords')})`">
                    <TableMultiselect
                        v-model="form.job_keywords"
                        :taggable="true"
                        :readonly="isCampaignReadOnly"
                        :placeholder="$t('create_campaign.select_existing_or_add_new_placeholder')"
                      />
                  </b-form-group>
                </div>
              </div>
              <hr>

              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('seeker_account.experience') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/networking.svg"
                      alt="Networking"
                      width="60"
                    />
                  </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <!-- <div class="form__title">Experience</div> -->
                  <b-form-group>
                    <b-form-checkbox v-model="form.international_experience">
                      {{ $t('applicant_portal.international_experience') }}
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    :label="`${$t('applicant_portal.years_of_experiences')}`"
                  >
                    <ValidationProvider v-slot="{ errors }">
                      <TableMultiselect
                        v-model="form.years_of_experiences"
                        :options="yearsOfExperience"
                      />
                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group>

                  <b-form-group
                    :label="`${$t('applicant_portal.seniority_level')}`"
                  >
                    <ValidationProvider v-slot="{ errors }">
                      <TableMultiselect
                        v-model="form.seniority_levels"
                        :options="senorityLevel"
                      />
                      <div class="error-message">{{ errors[0] }}</div>
                    </ValidationProvider>
                  </b-form-group>

                  <!-- <b-form-group :label="`${$t('applicant_portal.other_experience')} (${$t('create_campaign.keywords')})`">
                    <TableMultiselect
                        v-model="form.other_experience"
                        :options="keywords_options"
                        :taggable="true"
                        :readonly="isCampaignReadOnly"
                        :placeholder="$t('create_campaign.select_existing_or_add_new_placeholder')"
                      />
                  </b-form-group> -->
                </div>
              </div>
              <hr>

              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('applicant_portal.certificates') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/faq.svg"
                      alt="Certificates"
                      width="60"
                    />
                  </div>
                </div>

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <!-- <div class="form__title">Certificates</div> -->

                  <b-form-group :label="`${$t('applicant_portal.certificates')} (${$t('create_campaign.keywords')})`">
                    <TableMultiselect
                      v-model="form.certificates"
                      :taggable="true"
                      :readonly="isCampaignReadOnly"
                      :placeholder="$t('create_campaign.select_existing_or_add_new_placeholder')"
                    />
                  </b-form-group>
                </div>
              </div>
              <hr>

              <div class="form__section row">
                <div class="form__title col-lg-3 col-sm-12 text-center">
                  <div class="sticky-top pt-4">
                    <div class="mb-2">
                      {{ $t('seeker_account.documents') }}
                    </div>
                    <img
                      src="../../../images/campaign_icons/background_check.svg"
                      alt="Documents"
                      width="60"
                    />
                  </div>
                </div>
                <!-- <div class="form__title">{{ $t('seeker_account.documents') }}</div> -->

                <div class="col-lg-9 col-sm-12 pt-lg-4">
                  <b-form-group :label="`${$t('seeker_account.add_document')}`">
                    <TableMultiselect
                      v-model="form.required_documents"
                      :options="documentType"
                    />
                  </b-form-group>
                </div>
              </div>
              <!-- <hr v-if="!form.required_documents || !form.required_documents.length"/> -->

              <div class="col-12 p-0 d-flex justify-content-end">
                <b-button class="mt-4 wide" variant="primary" size="md" @click="validateForm()">{{
                  $t('general.save_changes')
                }}</b-button>
              </div>

            </b-form>
          </b-col>
        </b-row>
      </b-collapse>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import AppMultiselect from '@/components/CustomMultiselect';
import AppSelectInput from '@/components/SelectInput';
import CampaignHelpersMixin from '@/common/CampaignHelpersMixin';
import SeekerHelpersMixin from '@/common/SeekerHelpersMixin';
import ApplicantPortalMixin from '@/common/mixins/ApplicantPortalMixin';
import { CAMPAIGN_STATE } from '../../common/constants';

export default {
  name: 'JobAndSkillProfile',
  props: {
    isActivated: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: true,
    },
    current: {
      type: Object,
      default: null,
    },
  },
  mixins: [CampaignHelpersMixin, SeekerHelpersMixin, ApplicantPortalMixin],
  components: {
    AppMultiselect,
    AppSelectInput,
  },
  data() {
    return {
      keywords_options: [],
      form: {},
      isValid: false,
      step: 2,
    };
  },
  computed: {
    ...mapGetters('global', [
      'educationAreas',
      'educationGroups',
      'educationSpecializations',
      'jobAreas',
      'jobGroups',
      'jobSpecializations',
    ]),
    ...mapGetters({
      campaign: 'campaign/current',
    }),
    isCampaignReadOnly() {
      return this.current.state !== CAMPAIGN_STATE.OPEN;
    },
    showEditBtn() {
      return this.isActivated || this.isValid;
    }
  },
  created() {
    if (this.campaign.id && this.campaign.ap_data) {
      this.form = this.campaign.ap_data;

      this.$emit('currentForm', {
        applicant_portal: this.form,
      });
    }
  },
  mounted() {
    this.onEducationGroupsSearchChange('a');
    this.onJobGroupsSearchChange('a');
    this.onJobSpecializationsSearchChange('a');
  },
  methods: {
    onEducationAreasSearchChange(query) {
      this.$store.dispatch('global/searchEducationAreas', query)
        .finally(() => {}
        // this.$refs.educationAreasRef.hideLoader()
        )
    },
    onEducationGroupsSearchChange(query, ref) {
      this.$store.dispatch('global/searchEducationGroups', query)
        .finally(() => this.$refs.educationGroupsRef.hideLoader())
    },
    onEducationSpecializationsSearchChange(query) {
      this.$store.dispatch('global/searchEducationSpecializations', query)
        .finally(() => this.$refs.educationSpecializationsRef.hideLoader())
    },
    onJobAreasSearchChange(query) {
      this.$store.dispatch('global/searchJobAreas', query)
        .finally(() => this.$refs.jobAreasRef.hideLoader())
    },
    onJobGroupsSearchChange(query) {
      this.$store.dispatch('global/searchJobGroups', query)
        .finally(() => this.$refs.jobGroupsRef.hideLoader())
    },
    onJobSpecializationsSearchChange(query) {
      this.$store.dispatch('global/searchJobSpecializations', query)
        .finally(() => this.$refs.jobSpecializationsRef.hideLoader())
    },
    onFormChange() {},
    addTag(item) {
      this.keywords_options.push(item);
      this.$emit('add-keyword', item);
    },
    setIsValid(value) {
      this.isValid = value;
    },
    validateForm() {
      this.$refs.form.validate().then((res) => {
        const emitData = {
          id: 1,
          isValidForm: false,
          step: 2,
          disabled: true,
          data: {
            applicant_portal: this.form,
          },
        };

        if (res) {
          emitData.isValidForm = true;
          emitData.disabled = false;

          this.$root.$emit('bv::toggle::collapse', 'step-2');
          this.$root.$emit('bv::toggle::collapse', 'step-3');
        }
        this.$emit('isvalidform', emitData);
      });
    },
  },
};
</script>
