<template>
  <article class="campaign">
    <div :key="apCampaignRefreshKey" class="xmz__wizard">
      <b-row>
        <b-col>
          <img :src="companyLogo" class="d-none d-lg-block company-logo" />

          <header class="wizard__header">
            <h2>{{ $t('applicant_portal.applicant_portal_title') }}</h2>
            <h1 v-if="current.id">
              {{ $t('applicant_portal.edit_campaign') }}
            </h1>
            <h1 v-else>{{ $t('applicant_portal.create_campaign') }}</h1>
          </header>

          <div v-if="current.id" class="wizard__header__buttons">
            <b-btn
              class="mx-2 xxl"
              :disabled="false"
              variant="outline-secondary"
              @click="cloneCampaign"
            >
              <span class="mdi mdi-puzzle-check"></span>
              <span class="ml-2">{{
                $t('create_campaign.clone_campaign')
              }}</span>
            </b-btn>

            <!-- // save button ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
            <!--
            <b-btn class="mx-2 xxl" :disabled="false" variant="primary">{{
              $t('create_campaign.save_as_template')
            }}</b-btn> -->
          </div>

          <ModalCloneAPandExitCampaign
            v-if="showCloneModal"
            :currentCampaign="current"
            @onSubmit="() => (showCloneModal = false)"
            :title="''"
          />

          <UnsavedStepModal
            v-if="showUnsavedModal"
            :unsavedStep="unsavedStep"
            @close="() => (showUnsavedModal = false)"
          />

          <div class="step__list__container">
            <ol class="step__list">
              <!-- // step 1 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
              <li
                class="step__item"
                :before-change="() => validateForm('first')"
              >
                <div class="step__item__checkline">
                  <div
                    class="step__item__checkindicator"
                    :class="{ valid: formSteps[0].isValidForm }"
                  >
                    <span class="mdi mdi-check"></span>
                  </div>
                </div>

                <EnterCampaignDetailsView
                  ref="firstForm"
                  @isvalidform="isValidForm"
                  @relevantstep="changeStepRelevant"
                  :is-activated="isActivated"
                  :current="current"
                  @currentForm="updateCurrentForm"
                />
              </li>

              <!-- // step 2 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
              <li
                class="step__item"
                :before-change="() => validateForm('second')"
              >
                <div class="step__item__checkline">
                  <div
                    class="step__item__checkindicator"
                    :class="{ valid: formSteps[1].isValidForm }"
                  >
                    <span class="mdi mdi-check"></span>
                  </div>
                </div>

                <JobAndSkillProfileView
                  ref="secondForm"
                  @isvalidform="isValidForm"
                  @relevantstep="changeStepRelevant"
                  :is-activated="isActivated"
                  :current="current"
                  :is-disabled="formSteps[0].disabled"
                  @currentForm="updateCurrentForm"
                />
              </li>

              <!-- // step 3 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
              <li
                class="step__item"
                :before-change="() => validateForm('third')"
              >
                <div class="step__item__checkline">
                  <div
                    class="step__item__checkindicator"
                    :class="{ valid: formSteps[2].isValidForm }"
                  >
                    <span class="mdi mdi-check"></span>
                  </div>
                </div>

                <DefinePersonalityProfileView
                  ref="thirdForm"
                  @isvalidform="isValidForm"
                  @relevantstep="changeStepRelevant"
                  :is-activated="isActivated"
                  :is-disabled="formSteps[1].disabled"
                  @currentForm="updateCurrentForm"
                />
              </li>

              <!-- // step 4 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ -->
              <li class="step__item">
                <div class="step__item__checkline">
                  <div
                    class="step__item__checkindicator"
                    :class="{ valid: formSteps[3].isValidForm }"
                  >
                    <span class="mdi mdi-check"></span>
                  </div>
                </div>

                <ReviewAndActivateView
                  ref="fourthForm"
                  :is-disabled="formSteps[2].disabled"
                  :current="current"
                  :timeZone="timeZone"
                  :timeZones="timeZones"
                  :is-activated="isActivated"
                  @activate="checkIrrelevantStep('activate', $event)"
                  @save="checkIrrelevantStep('save', $event)"
                  @currentForm="updateCurrentForm"
                />
              </li>
            </ol>
          </div>
        </b-col>
      </b-row>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { forEach, merge, omit } from 'lodash';
import { resourceAbsUrl } from '@/common/utils';
import CampaignHelpersMixin from '@/common/CampaignHelpersMixin';
import ApplicantPortalMixin from '@/common/mixins/ApplicantPortalMixin';
import EnterCampaignDetailsView from './EnterCampaignDetails';
import JobAndSkillProfileView from './JobAndSkillProfile';
import DefinePersonalityProfileView from './DefinePersonalityProfile';
import ReviewAndActivateView from './ReviewAndActivate';
import { CAMPAIGN_STATE } from '../../common/constants';
import ModalCloneAPandExitCampaign from '../../components/ModalCloneAPandExitCampaign';
import UnsavedStepModal from './UnsavedStepModal';

import AppUseTemplateModal from './UseTemplateModal';
import ModalExitIcon from '../../icons/modalExit';
import franchise from '../../conf/franchise';

export default {
  components: {
    EnterCampaignDetailsView,
    JobAndSkillProfileView,
    DefinePersonalityProfileView,
    ReviewAndActivateView,
    ModalExitIcon,
    AppUseTemplateModal,
    ModalCloneAPandExitCampaign,
    UnsavedStepModal,
  },
  mixins: [CampaignHelpersMixin, ApplicantPortalMixin],
  data() {
    return {
      showCloneModal: false,
      showUnsavedModal: false,
      keywords_options: [],
      form: {
        is_applicant_portal: true,
        info: {
          include_analysis: true,
        },
      },
      formSteps: [
        { isValidForm: false, step: 1, disabled: true, relevant: true },
        { isValidForm: false, step: 2, disabled: true, relevant: true },
        { isValidForm: false, step: 3, disabled: true, relevant: true },
        { isValidForm: false, step: 4, disabled: true, relevant: true },
      ],
      timeZone: null,
    };
  },
  computed: {
    ...mapGetters('campaign', ['current', 'apCampaignRefreshKey']),
    ...mapGetters('global', [
      'currentLanguage',
      'timeZones',
      'titles',
      'currentUserCompanyId',
      'companyLogoUrl',
    ]),
    companyLogo() {
      return this.companyLogoUrl
        ? resourceAbsUrl(this.companyLogoUrl)
        : franchise.logo;
    },
    currentFormStep() {
      return this.payload.step;
    },
    isActivated() {
      return (
        this.current.state !== CAMPAIGN_STATE.OPEN &&
        this.current.state !== undefined
      );
    },
    unsavedStep() {
      return this.formSteps.find((el) => !el.relevant);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.collapseCurrentFrom();
    }
  },
  methods: {
    scrollTop() {
      this.$el.parentElement.parentElement.scrollTop = 140;
    },
    saveForm(payload) {
      this.isValidForm(payload, true);
    },
    isValidForm(payload) {
      const save = payload.id === 0 || payload.id === 1 || payload.id === 3;
      if (payload.isValidForm && (!this.isActivated || save)) {
        this.form = merge(this.form, payload.data);

        // TMP hack!
        if (payload.id === 1) {
          this.form.applicant_portal = payload.data.applicant_portal;
        }

        // Override personality_attrs
        this.form.personality_attrs = payload.data.personality_attrs;

        const tmpForm = Object.assign({ state: this.current.state }, this.form);

        if (this.current.id) tmpForm.id = this.current.id;
        if (this.form.time_zone) tmpForm.time_zone = this.form.time_zone.value;

        // Remove missing data from template
        if (!tmpForm.state) tmpForm.state = CAMPAIGN_STATE.OPEN;
        if (!tmpForm.personality_attrs) delete tmpForm.personality_attrs;

        this.$store
          .dispatch(
            this.current.id
              ? 'campaign/updateCampaign'
              : 'campaign/createCampaign',
            tmpForm
          )
          .then(() => {
            if (!this.$route.params.id) {
              this.$router.push(`/campaigns/ap/${this.current.id}/edit`);
            }
          });
      } else if (payload.id === 2 && this.isActivated) {
        // Recalculate report
        this.$store.dispatch('campaign/recalculateReport', {
          campaignId: this.current.id,
          companyId: this.currentUserCompanyId,
          data: payload.data,
        });
      }

      this.formSteps[payload.id].isValidForm = payload.isValidForm;
      this.formSteps[payload.id].step = payload.step;
      this.formSteps[payload.id].disabled = payload.disabled;
      this.formSteps[payload.id].relevant = true;
      this.scrollTop();
    },
    validateForm(name) {
      const forms = {
        first: this.$refs.firstForm,
        second: this.$refs.secondForm,
        third: this.$refs.thirdForm,
      };
      // Check references and reference method and validate else return valid
      return name in forms && forms[name].validateForm
        ? forms[name].validateForm()
        : true;
    },
    activateCampaign(data) {
      // TODO: check do we need to merge or assign!
      this.form = omit(merge(this.form, data), 'applicant_portal');
      // Format time zone
      this.form.time_zone = this.form.time_zone.value;

      let action = 'campaign/createCampaign';
      if (this.current.id) {
        this.form.id = this.current.id;
        action = 'campaign/updateCampaign';
      } else {
        if (!('state' in tmpForm)) {
          this.form.state = CAMPAIGN_STATE.OPEN;
        }
      }

      this.$store
        .dispatch(action, this.form)
        .then(() => {
          this.$refs.fourthForm.toggleActivatedModal();
        })
        .catch((error) => {
          this.loading = false;
          const alertMessage = error.response.data.error;
          this.$bvToast.toast(alertMessage, {
            autoHideDelay: 2000,
            variant: 'warning',
            title: 'Error',
          });
        });

      this.form = {
        is_applicant_portal: true,
        info: {
          include_analysis: true,
        },
      };
    },
    updateCurrentForm(currentForm) {
      this.form = merge(this.form, currentForm);
    },
    collapseCurrentFrom() {
      if (this.current.state === CAMPAIGN_STATE.OPEN) {
        this.$nextTick(async () => {
          const refs = [
            this.$refs.firstForm,
            this.$refs.secondForm,
            this.$refs.thirdForm,
            this.$refs.fourthForm,
          ];

          // Loadash forEach can be returned if it's needed
          forEach(refs, async (form, index) => {
            const isValid = await form.$refs.form.validate();
            if (isValid) {
              this.formSteps[index] = {
                isValidForm: true,
                step: index + 1,
                disabled: false,
                relevant: true,
              };
              // Toggle edit button
              form.setIsValid(true);
            } else {
              this.$root.$emit('bv::toggle::collapse', `step-${index + 1}`);
              return false;
            }
          });
        });
      } else {
        this.formSteps = [
          { isValidForm: true, step: 1, disabled: false, relevant: true },
          { isValidForm: true, step: 2, disabled: false, relevant: true },
          { isValidForm: true, step: 3, disabled: false, relevant: true },
          { isValidForm: true, step: 4, disabled: false, relevant: true },
        ];
        this.$root.$emit('bv::toggle::collapse', 'step-4');
      }
    },
    cloneCampaign() {
      this.showCloneModal = !this.showCloneModal;
    },
    changeStepRelevant(step) {
      if (this.formSteps[step - 1].relevant) {
        this.formSteps[step - 1].relevant = false;
      }
    },
    checkIrrelevantStep(actionType, payload) {
      if (this.unsavedStep) {
        this.showUnsavedModal = true;
      } else {
        const actions = {
          activate: this.activateCampaign,
          save: this.isValidForm,
        };
        actions[actionType](payload);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.wizard__header__buttons {
  padding-bottom: 20px;
  margin-left: 180px;
  width: calc(100% - 180px);
  max-width: 760px;
  text-align: right;
}
</style>